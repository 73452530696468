import React from "react"
import styled from "styled-components"

import { formatDateTimeToString } from "../../utils/date"

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  grid-template-areas:
    "logo title title"
    "logo createdAt createdAt"
    "message message message";
  column-gap: 10px;
  row-gap: 0px;
  padding: 20px 0px 40px 0px;

  img {
    grid-area: logo;
    width: 40px;
  }

  h4 {
    grid-area: title;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 0;
  }

  .created-at {
    grid-area: createdAt;
    font-size: 11px;
    color: ${({ theme }) => theme.palette.doveGrey};
    margin-bottom: 0px;
    margin-top: 7px;
  }

  .message {
    grid-area: message;
    max-width: 750px;
    font-size: 14px;
    line-height: 22px;
    margin-top: 15px;
    white-space: pre-line;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: max-content max-content max-content 1fr;
    grid-template-areas:
      "logo title createdAt ."
      "logo message message message";
    padding: 20px 60px 40px 0px;
    row-gap: 3px;

    .message {
      margin: 0;
    }
  }
`

interface Props {
  content: Yoco_notifications.Notification
}

const YocoNotificationCard: React.FC<Props> = ({ content }) => {
  return (
    <Container>
      <img src="/images/yoco-logo-circle.svg" alt="Yoco Logo" />
      <h4>{content.heading}</h4>
      <p className="created-at">{formatDateTimeToString(content.createdAt)}</p>
      <p className="message">{content.message} </p>
    </Container>
  )
}

export default YocoNotificationCard
