import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { useGetNotifications, useUser } from "../../auth/hooks"
import {
  PageHeader,
  PageGuard,
  PageNotificationBanner,
  Card,
} from "../../components"
import Seo from "../../components/seo"
import YocoNotificationCard from "../../notifications/components/YocoNotificationCard"

const CustomPageCard = styled(Card)`
  flex: 1;
`

const NotificationsContent = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .yoco-alerts-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 15px;

    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }

    img {
      width: 11px;
    }
  }

  .notifications-list {
    width: 100%;
    flex: 1;
    overflow-y: scroll;

    .notifications-list-header {
      margin-bottom: 10px;

      img {
        margin-bottom: 20px;
      }

      h3 {
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 15px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }
  }
`

const Divider = styled.hr`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.mystic};
  margin-bottom: 10px;

  &.small {
    width: 90%;
  }
`

const EmptyListMessage = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-top: 40px;
`

const ScrollIndicator = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white};
  padding: 10px 0px;

  p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.palette.doveGrey};
  }
`

const Notifications = (): React.ReactElement => {
  const [data, loading] = useGetNotifications()
  const { user } = useUser()

  return (
    <PageGuard>
      <Seo title="Notifications" />
      <PageHeader
        cypressTestId="notifications-page-header"
        title="Notifications"
      />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to see notifications from Yoco.
          </p>
        ) : (
          <p>View your notifications from Yoco.</p>
        )}
      </PageNotificationBanner>
      <CustomPageCard padding="20px 20px 40px 20px" backgroundColor="#fff">
        <NotificationsContent>
          <div className="yoco-alerts-header">
            <h2>@Yoco Alerts</h2>
            {!loading && data?.length !== 0 ? (
              <img
                src="/images/icons/icon-incomplete.svg"
                alt="Active notification badge"
              />
            ) : null}
          </div>
          <Divider />
          <div className="notifications-list">
            <div className="notifications-list-header">
              <img src="/images/yoco-logo-circle.svg" alt="Yoco Logo" />
              <h3>Yoco Alerts</h3>
              <p>
                This is where you will receive all automated notifications from
                the Yoco Reseller Portal.
              </p>
              <Divider className="small" />
            </div>
            {!loading && data?.length !== 0 ? (
              data?.map((notification, index) => (
                <div key={`${notification.heading}-${notification.createdAt}`}>
                  <YocoNotificationCard content={notification} />
                  {index !== data.length - 1 ? (
                    <Divider className="small" />
                  ) : null}
                  <ScrollIndicator>
                    <p>Scroll for older messages</p>
                  </ScrollIndicator>
                </div>
              ))
            ) : (
              <EmptyListMessage>
                You have no active notifications at the moment. Check back
                later.
              </EmptyListMessage>
            )}
          </div>
        </NotificationsContent>
      </CustomPageCard>
    </PageGuard>
  )
}

export default Notifications
